import { DeleteOutline, KeyboardArrowDown } from '@mui/icons-material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { CONSTANTS } from '../../../Constants/Constant'
import { getDateInFormat } from '../../../Helpers/Util'
import AssetStateLabel from '../../Labels/AssetStateLabel'
import InOutWindow from '../../ManageContent/ManageAsset/AssetEditor/InOutWindow'
import AVSInjestionStatus from '../../ManageContent/ManageAsset/AssetEditor/VideoEditor/AVSInjestionStatus'
import { MUIGrid } from '../../Shared'
import MUITooltip from '../../Shared/MuiTooltipComponent'
import style from '../Molecule.module.scss'

interface HeaderComponentModal {
  isAnyMetaPermission?: boolean
  assetType?: string
  title?: string
  status?: string
  blitzId?: string | number
  contentID?: number | string
  creationDate?: any
  updatedTime?: any
  lastPublishedOn?: any
  onDeleteIconClick?: () => void
  assetOtherDetails?: any
  assetDetails?: any
  isAuditTrail?: boolean
  avsCutoverId?: number | string
}
const AssetHeaderComponent: React.FC<HeaderComponentModal> = ({
  isAnyMetaPermission = true,
  assetType = 'NA',
  title = 'NA',
  status = 'NA',
  blitzId = 'NA',
  contentID = 'NA',
  creationDate = 'NA',
  updatedTime = 'NA',
  lastPublishedOn = 'NA',
  onDeleteIconClick,
  assetOtherDetails = {},
  isAuditTrail = false,
  avsCutoverId = 'NA',
}) => {
  const { pageType } = useParams()
  const [showDetails, setShowDetails] = useState(false)
  const canDelete =
    status !== CONSTANTS.ASSET_STATUS.PUBLISHED && status !== CONSTANTS.ASSET_STATUS.CREATED

  const getAssetId = () => {
    if (!isAuditTrail) {
      return (
        <>
          {blitzId}
          {showDetails ? (
            <KeyboardArrowUpIcon className='col33' />
          ) : (
            <KeyboardArrowDown className='col33' />
          )}
        </>
      )
    } else {
      return <>{contentID} </>
    }
  }
  return (
    <div style={{ position: 'relative' }} className={isAuditTrail ? 'mb-1 mt-2' : ''}>
      <InOutWindow assetOtherDetails={assetOtherDetails} />
      <AVSInjestionStatus assetOtherDetails={assetOtherDetails} />
      <div
        className={`${isAuditTrail ? '' : 'cursor-pointer '}mt-1`}
        data-testid='asset_header_accordion'
        onClick={() => {
          if (!isAuditTrail) setShowDetails(!showDetails)
        }}
      >
        <MUIGrid
          container
          className={`d-flex justify-content-between align-items-start ${style.asset_header_static}`}
        >
          <MUIGrid className='d-flex align-items-center gap-12'>
            <span style={{ fontWeight: '400' }} className='col34'>
              Asset Type :
            </span>
            <span className={`col36 capitalize ${style.f_12_w_600}`}>{assetType}</span>
            <span className='col36'>
              <AssetStateLabel state={status} />
            </span>
          </MUIGrid>
          <MUIGrid
            className='d-flex align-items-start gap-6'
            style={{ maxWidth: 'calc(100% - 560px)' }}
          >
            <span className='col34' style={{ fontWeight: '400' }}>
              Title :
            </span>
            <span
              className={`col36 ${style.header_value} textEllipsesLine-3 word-break-all mt-neg2`}
            >
              {title || 'NA'}
            </span>
          </MUIGrid>
          <MUIGrid className='d-flex align-items-center gap-12'>
            <span className='col34'>{isAuditTrail ? 'Content Id' : 'Blitz Id'} :</span>
            <span className={`col36 ${style.w_600}`}>
              <span className='d-flex align-items-center gap-18'>{getAssetId()}</span>
            </span>
          </MUIGrid>
        </MUIGrid>
      </div>
      {showDetails || isAuditTrail ? (
        <MUIGrid
          container
          id='create-asset-header-details'
          className={`d-flex justify-content-between align-items-center ${style.asset_header_dynamic_bottom}`}
        >
          <MUIGrid className='d-flex align-items-center gap-12'>
            <span style={{ fontWeight: '400' }} className='col34'>
              Created On :
            </span>
            <span className={`col36 ${style.header_value}`}>{creationDate}</span>
          </MUIGrid>
          {/* <MUIGrid className='d-flex align-items-center gap-12'>
            <span style={{ fontWeight: '400' }} className='col34'>
              Last Updated On :
            </span>
            <span className={`col36 ${style.header_value}`}>{updatedTime}</span>
          </MUIGrid> */}
          <MUIGrid className='d-flex align-items-center gap-12'>
            <span style={{ fontWeight: '400' }} className='col34'>
              Last Published On :
            </span>
            <span className={`col36 ${style.header_value}`}>
              {assetOtherDetails?.lastPublished
                ? getDateInFormat(assetOtherDetails?.lastPublished, CONSTANTS.DATE_FORMAT_TIME)
                : lastPublishedOn}
            </span>
          </MUIGrid>
          {!isAuditTrail && assetType !== 'VIDEO_SHORTS' && (
            <MUIGrid className='d-flex align-items-center gap-12'>
              <span style={{ fontWeight: '400' }} className='col34'>
                AVS Content Id :
              </span>
              <span className={`col36 ${style.header_value}`}>{contentID}</span>
            </MUIGrid>
          )}
          {assetType !== 'VIDEO_SHORTS' && (
            <MUIGrid className='d-flex align-items-center gap-12'>
              <span style={{ fontWeight: '400' }} className='col34'>
                Featuring Id :
              </span>
              <span className={`col36 ${style.header_value}`}>{avsCutoverId}</span>
            </MUIGrid>
          )}
          {pageType !== CONSTANTS.PAGE_TYPE.VIEW_ONLY && onDeleteIconClick && (
            <div
              onClick={() => {
                if (canDelete && isAnyMetaPermission) onDeleteIconClick()
              }}
              className={`col37 d-flex align-items-center ${
                canDelete && isAnyMetaPermission ? 'cursor-pointer' : 'disabled'
              }`}
              data-testid='deleteIcon__test'
            >
              <MUITooltip title='Delete Asset Version' disableInteractive={true}>
                <DeleteOutline className='col37' />
              </MUITooltip>
            </div>
          )}
        </MUIGrid>
      ) : (
        <></>
      )}
    </div>
  )
}

export default AssetHeaderComponent
