import { subStringWithEllipses } from '../../Helpers/Util'
import { TypeObject } from '../../Models'
import { CONSTANTS } from '../Constant'
import { PAGES_ROUTES, PAGE_NAME } from '../PagesRoutes'

const BREADCRUMS_LABEL = {
  HOME: 'Home',
  ROLE_MANAGEMENT: 'Role Management',
  ROLE: 'Role',
  USER_MANAGEMENT: 'User Management',
  USER: 'User',
  ASSET_TYPE_DEFINITION: 'Asset Definition',
  ASSET: 'Asset',
  PARTNER: 'Partner',
  CREATE_ASSET: 'Create Asset',
  UPDATE_ASSET: 'Update Asset',
  CREATE_CHILD_ASSET: 'Create Child Asset',
  UPDATE_CHILD_ASSET: 'Update Child Asset',
  VIEW_ASSET: 'View Child Asset',
  SHOW: 'Show',
  ADVANCED_SEARCH: 'Advance Search',
  PARTNER_MANAGEMENT: 'Inbound Partners',
  MY_DASHBOARD: 'My Dashboard',
}

type TypeStringNumberUndefined = string | number | undefined

const getTooltip = (grandParentDetails: any) => {
  let title = 'NA'
  if (grandParentDetails) {
    grandParentDetails?.metaCategories?.forEach((item: any) => {
      if (item?.metaCategory === 'DESCRIPTIVE_METADATA') {
        title = item?.assetTitle
      }
    })
  }
  return title
}

export const BREADCRUMS = {
  CREATE_ROLE: (id = '', title = '') => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.ROLE_MANAGEMENT, path: `/${PAGES_ROUTES.RolesPage}` },
    {
      label: `${id === CONSTANTS.PAGE_TYPE.CREATE ? 'Create Role' : title}`,
      path: `/${PAGES_ROUTES.RolesPage}/${id}`,
      active: true,
    },
  ],
  CREATE_USER: (id: TypeStringNumberUndefined, type: string, userName = '') => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.USER_MANAGEMENT, path: `/${PAGES_ROUTES.ManageUser}` },
    {
      label: `${type === CONSTANTS.PAGE_TYPE.CREATE ? 'Create User' : userName}`,
      path: `/${PAGES_ROUTES.ManageUser}/${id}`,
      active: true,
    },
  ],
  CREATE_PARTNER: (id: TypeStringNumberUndefined, type: string, serviceName = '') => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: BREADCRUMS_LABEL.PARTNER_MANAGEMENT, path: `/${PAGES_ROUTES.Partner}` },
    {
      label: `${type === CONSTANTS.PAGE_TYPE.CREATE ? 'Create Partner' : serviceName}`,
      path: `/${PAGES_ROUTES.ManageUser}/${id}`,
      active: true,
    },
  ],
  CREATE_ASSET_TYPE: (
    id: TypeStringNumberUndefined,
    type: string,
    assetDefinitionTitle: string,
  ) => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    {
      label: 'Meta Master',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.AssetsDefination}`,
    },
    {
      label: BREADCRUMS_LABEL.ASSET_TYPE_DEFINITION,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.AssetsDefination}`,
    },
    {
      label: `${type === CONSTANTS.PAGE_TYPE.CREATE ? 'Create Asset Type' : assetDefinitionTitle}`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.AssetsDefination}/${id}`,
      active: true,
    },
  ],
  VIEW_USER_ROLE: (id = '', type = '', roleName = '') => [
    {
      image: require('../../Assets/Images/bento-menu-icon.png'),
      label: BREADCRUMS_LABEL.HOME,
      path: PAGES_ROUTES.RootPage,
    },
    { label: 'Role Management', path: '/manage-role' },
    {
      label: `${roleName}`,
      path: `/manage-role/${id}/${type}`,
    },
    {
      label: 'Associated Users',
      path: `/manage-role/${id}/${type}/users`,
      active: true,
    },
  ],
  CREATE_ATTRIBUTE: (id: TypeStringNumberUndefined, type: string, attributeLabel: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Meta Master',
      path: '/metamaster/attribute-repo',
    },
    {
      label: 'Attribute Repository',
      path: '/metamaster/attribute-repo',
    },
    {
      label: `${type === CONSTANTS.PAGE_TYPE.CREATE ? 'Create Attribute' : attributeLabel}`,
      path: `/metamaster/attribute-repo/${id}`,
      active: true,
    },
  ],
  VIEW_GEO_POLICY: (id: string, type: TypeStringNumberUndefined, policyName: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    { label: 'Meta Master', path: '/metamaster/geo-policy' },
    {
      label: 'Geo Policy Management',
      path: '/metamaster/geo-policy',
    },
    {
      label: `${type === CONSTANTS.PAGE_TYPE.CREATE ? 'Create Policy' : policyName}`,
      path: '/',
      active: true,
    },
  ],

  SAVE_SEARCH_LIST: () => [
    { label: 'Save Search', path: '/asset-state-details/save-search', active: true },
  ],
  CREATE_ASSET: (data: TypeObject) => {
    let arrayObject: Array<TypeObject> = [
      { label: PAGE_NAME.HomeLabel, path: PAGES_ROUTES.HomePage },
      { label: PAGE_NAME.ManageContentLabel, path: `/${PAGES_ROUTES.ManageContent}/tv_show` },
      {
        label: data?.conCategoryLabel,
        path: `/${PAGES_ROUTES.ManageContent}/${data?.conCategory?.toLowerCase()}`,
      },
    ]
    const assetPath = `/asset/${data?.pageType}/${data?.contentId}/${data?.assetType}/${data?.conCategory}/${data?.assetCategory}/${data?.metaCategory}`
    if (data?.greatGrandParentDetails) {
      arrayObject = [
        ...arrayObject,
        {
          label: `${data.greatGrandParentDetails.blitzId} `,
          showTooltip: true,
          tooltipText: getTooltip(data.greatGrandParentDetails),
          path: `/asset/${data.pageType}/${data.greatGrandParentDetails._id}/${data.greatGrandParentDetails.assetType}/${data.greatGrandParentDetails.contentCategory}/${data.greatGrandParentDetails.assetCategory}/${data.metaCategory}`,
        },
      ]
    }
    if (data?.grandParentDetails) {
      arrayObject = [
        ...arrayObject,
        {
          label: `${data.grandParentDetails.blitzId} `,
          showTooltip: true,
          tooltipText: getTooltip(data.grandParentDetails),
          path: `/asset/${data.pageType}/${data.grandParentDetails._id}/${data.grandParentDetails.assetType}/${data.grandParentDetails.contentCategory}/${data.grandParentDetails.assetCategory}/${data.metaCategory}`,
        },
      ]
    }
    if (data?.parentDetails) {
      arrayObject = [
        ...arrayObject,
        {
          label: `${data.parentDetails.blitzId}`,
          showTooltip: true,
          tooltipText: `${subStringWithEllipses(data?.parentDetails?.assetTitle ?? 'NA')}`,
          path: `/asset/${data.pageType}/${data.parentDetails._id}/${data.parentDetails.assetType}/${data.parentDetails.contentCategory}/${data.parentDetails.assetCategory}/${data.metaCategory}`,
        },
        {
          label:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY
              ? `${data?.blitzId}`
              : data.state !== 'CREATED'
              ? `${data?.blitzId}`
              : BREADCRUMS_LABEL.CREATE_CHILD_ASSET,
          showTooltip:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY || data.state !== 'CREATED'
              ? true
              : false,
          tooltipText: `${data?.title}`,
          path: `/asset/${data.pageType}/${data.contentId}/${data.assetType}/${data.conCategory}/${data.assetCategory}/${data.metaCategory}`,
        },
      ]
    } else {
      arrayObject = [
        ...arrayObject,
        {
          label:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY
              ? `${data?.blitzId || ''}`
              : data?.state !== 'CREATED'
              ? `${data?.blitzId || ''}`
              : BREADCRUMS_LABEL.CREATE_ASSET,
          showTooltip:
            data.pageType === CONSTANTS.PAGE_TYPE.VIEW_ONLY || data.state !== 'CREATED'
              ? true
              : false,
          tooltipText: `${data?.title}`,
          path: assetPath,
        },
      ]
    }
    if (data?.isAssociated && data?.blitzId) {
      arrayObject.push({
        label: PAGE_NAME.AssociatedAssests,
        path: `${assetPath}/assoiciated-assets`,
      })
    } else if (data?.isAuditTrails) {
      arrayObject.push({
        label: PAGE_NAME.AuditTrailsLabel,
        path: `${assetPath}/audit-trails/${data?.contentIdNumber}/${data?.blitzId}`,
      })
      if (data?.isAuditTrailsView) {
        arrayObject.push({
          label: 'View Log',
          path: `${assetPath}/audit-trails/${data?.contentIdNumber}/${data?.blitzId}/view/${data?.auditId}`,
        })
      }
    }
    if (data?.type === 'bulk-image-upload') {
      arrayObject = [
        ...arrayObject,
        {
          label: 'Content Media: Image',
          path: `${assetPath}`,
        },
        {
          label: 'Bulk Upload',
          path: `${assetPath}`,
        },
      ]
    }

    arrayObject[arrayObject.length - 1].active = true

    return arrayObject
  },
  CREATE_AFFILIATE_PARTNERS: (id: TypeStringNumberUndefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Affiliate Partner',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/affiliate-partners`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.AffiliatePartners}/${id}`,
      active: true,
    },
  ],
  CREATE_COUNTRIES: (id: TypeStringNumberUndefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Country',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/countries`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Countries}/${id}`,
      active: true,
    },
  ],
  CREATE_LANGUAGES: (id: TypeStringNumberUndefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Language',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/languages`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.Languages}/${id}`,
      active: true,
    },
  ],
  CREATE_DOWNSTREAM_PLATFORMS: (id: TypeStringNumberUndefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Downstream Platform',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/downstream-platforms`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.DownstreamPlatforms}/${id}`,
      active: true,
    },
  ],
  CREATE_ENTITLEMENT_PACKAGES: (id: TypeStringNumberUndefined, type: string) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Entitlement Package',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/entitlement-packages`,
    },
    {
      label: `${type} Value`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}/${id}`,
      active: true,
    },
  ],
  CREATE_SYSTEMCONFIGURATION: (
    mastersPageType: TypeStringNumberUndefined,
    title: string,
    systemConfigurationPageType: string,
    name: string,
    url: string,
  ) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: `${name}`,
      path: `${url}`,
    },
    {
      label: `${title} ${systemConfigurationPageType}`,
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}/${PAGES_ROUTES.EntitlementPackages}/${mastersPageType}`,
      active: true,
    },
  ],
  VIEW_USERS_ROLES_LOGS: (id: TypeStringNumberUndefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.UsersRolesLogs}`,
    },
    { label: 'User Management Logs', path: `/${PAGES_ROUTES.UsersRolesLogs}` },
    {
      label: 'View User Management Logs',
      path: `/${PAGES_ROUTES.UsersRolesLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_MASTER_LOGS: (id: TypeStringNumberUndefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.MasterLogs}`,
    },
    { label: 'Master Logs', path: `/${PAGES_ROUTES.MasterLogs}` },
    {
      label: 'View Master Logs',
      path: `/${PAGES_ROUTES.MasterLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_CONTENT_LOGS: (id: TypeStringNumberUndefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.ContentLogs}`,
    },
    { label: 'Content Logs', path: `/${PAGES_ROUTES.ContentLogs}` },
    {
      label: 'View Content Logs',
      path: `/${PAGES_ROUTES.ContentLogs}/${id}/${type}`,
      active: true,
    },
  ],
  VIEW_TRANSCODING_LOGS: (id: TypeStringNumberUndefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Audit Logs',
      path: `/${PAGES_ROUTES.TranscodingLogs}`,
    },
    { label: 'Transcoding Logs', path: `/${PAGES_ROUTES.TranscodingLogs}` },
    {
      label: 'View Transcoding Logs',
      path: `/${PAGES_ROUTES.TranscodingLogs}/${id}/${type}`,
      active: true,
    },
  ],
  AUDIT_TRAILS: (id: TypeStringNumberUndefined, type?: string | number) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    { label: 'Audit Trails', path: `/${PAGES_ROUTES.AuditTrails}` },
    {
      label: 'View Audit Trails',
      path: `/${PAGES_ROUTES.AuditTrails}/${id}/${type}`,
      active: true,
    },
  ],
  ASSET_STATUS_LISTING: (pageTitle: string | undefined) => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/home' },
    { label: 'Asset State', path: '/home' },
    { label: `${pageTitle}`, path: `/${pageTitle}`, active: true },
  ],
  ADVANCED_SEARCH_BREADCRUMS: (widgetName = '', savedSearchName = '') => {
    if (widgetName || savedSearchName) {
      return [
        { label: BREADCRUMS_LABEL.HOME, path: PAGES_ROUTES.HomePage },
        { label: BREADCRUMS_LABEL.MY_DASHBOARD, path: PAGES_ROUTES.HomePage },
        {
          label: `${widgetName || savedSearchName}`,
          path: PAGES_ROUTES.HomePage,
          active: true,
        },
      ]
    }
    return [
      { label: BREADCRUMS_LABEL.HOME, path: PAGES_ROUTES.HomePage },
      { label: BREADCRUMS_LABEL.ADVANCED_SEARCH, path: PAGES_ROUTES.HomePage, active: true },
    ]
  },
  CREATE_MASTER_INFORMATION: () => [
    { image: require('../../Assets/Images/bento-menu-icon.png'), label: 'Home', path: '/' },
    {
      label: 'Masters',
      path: `/${PAGES_ROUTES.MetaMaster}/${PAGES_ROUTES.Masters}`,
    },
    {
      label: 'Create Master',
      path: `/${PAGES_ROUTES.CreateSystemConfigurationPageType}/${PAGES_ROUTES.CreateSystemConfirgurationPage}`,
      active: true,
    },
  ],
  CREATE_UPDATE_BULK_UPDATE: (jobName: string) => [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Bulk Update',
      path: '/bulk-update',
    },
    {
      label: jobName?.length ? `${jobName}` : '',
      path: '/bulk-update',
      active: true,
    },
  ],
  VIEW_BULK_UPDATE: (jobName: string) => [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Bulk Update',
      path: '/bulk-update',
    },
    {
      label: jobName?.length ? `${jobName}` : '',
      path: '/bulk-update/view',
      active: true,
    },
  ],
}
