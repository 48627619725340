import ActivateRoleIcon from '../../../Assets/Images/activate-role-icon.png'
import ActivateUserIcon from '../../../Assets/Images/activate-user-icon.png'
import ActiveIcon from '../../../Assets/Images/active-icon.png'
import CopyClipboardIcon from '../../../Assets/Images/clone-icon.png'
import DeactivateRoleIcon from '../../../Assets/Images/deactivate-role-icon.png'
import DeactivateUserIcon from '../../../Assets/Images/delete-deactivate-user-icon.png'
import DeleteRoleIcon from '../../../Assets/Images/delete-role-icon.png'
import DeleteRoleWarIcon from '../../../Assets/Images/delete-role-warn-icon.png'
import DialogEditIcon from '../../../Assets/Images/dialog-edit-icon.svg'
import DialogViewIcon from '../../../Assets/Images/dialog-view-icon.svg'
import ExportLimitCloseIcon from '../../../Assets/Images/export-limit-close-icon.png'
import AssetLinkGridWarnIcon from '../../../Assets/Images/featured-icon-red.png'
import AssetLinkGridIcon from '../../../Assets/Images/featured-icon.png'
import GreentickIcon from '../../../Assets/Images/gren-tick-icon.png'
import InactiveIcon from '../../../Assets/Images/inactive-icon.png'
import IngestionReportErrorIcon from '../../../Assets/Images/svgs/ingestion-report-error.svg'
import PinnedIcon from '../../../Assets/Images/svgs/pined-icon.svg'

interface IProps {
  IconName: any
}

const ModalIcon = ({ IconName }: IProps) => {
  return (
    <>
      <img
        src={`${
          IconName === 'ActivateRoleIcon'
            ? ActivateRoleIcon
            : IconName === 'DeactivateRoleIcon'
            ? DeactivateRoleIcon
            : IconName === 'DeleteRoleIcon'
            ? DeleteRoleIcon
            : IconName === 'DeactivateUserIcon'
            ? DeactivateUserIcon
            : IconName === 'DeleteRoleWarIcon'
            ? DeleteRoleWarIcon
            : IconName === 'ActivateUserIcon'
            ? ActivateUserIcon
            : IconName === 'ActiveIcon'
            ? ActiveIcon
            : IconName === 'InactiveIcon'
            ? InactiveIcon
            : IconName === 'AssetGridIcon'
            ? AssetLinkGridIcon
            : IconName === 'AssetGridWarnIcon'
            ? AssetLinkGridWarnIcon
            : IconName === 'CopyClipboardIcon'
            ? CopyClipboardIcon
            : IconName === 'GreentickIcon'
            ? GreentickIcon
            : IconName === 'DialogEditIcon'
            ? DialogEditIcon
            : IconName === 'DialogViewIcon'
            ? DialogViewIcon
            : IconName === 'Pinned'
            ? PinnedIcon
            : IconName === 'ExportLimitCloseIcon'
            ? ExportLimitCloseIcon
            : IconName === 'IngestionReportErrorIcon'
            ? IngestionReportErrorIcon
            : ''
        }
      `}
      />
    </>
  )
}

export default ModalIcon
