import { format } from 'date-fns'
import getJobStatus, { bulkUpdateListingAction } from '../Components/BulkUpdate/BulkUpdateHelper'
import { dayOption } from '../Components/GeoPolicy/GeoPolicyEditor/GeoPolicyConstant'
import commonListingEditBtn from '../Components/Shared/Badges/Edit'
import commonListingReportActionBtn from '../Components/Shared/Badges/ReportAction'
import getStatus from '../Components/Shared/Badges/Status/Index'
import CONSTANTS from '../Constants'
import {
  ASSET_TYPE_DEFINITION_CRUD,
  ATTRIBUTE_REPOSITORY_CRUD,
  AUDIT_LOGS,
  BULK_UPDATE_APIS,
  GEO_POLICY_CRUD,
  PARTNER_APIS,
  ROLE_MANAGEMENT_CRUD,
  USER_MANAGEMENT_CRUD,
} from '../Constants/ApiDefinations'
import {
  AssetDefinationListResponseModal,
  AttributeRepoListResponseModal,
  GeoPolicyListResponseModal,
  ListAPIDefinationModal,
  MasterListResponseModal,
  MasterSysConfigListResponseModal,
  RoleListResponseModal,
  UserListResponseModal,
} from '../Models/CommonListing'
import mainApiService from '../Services'
import { clearSystemConfigurationStore } from '../Store/Slice/system-configuration.slice'
import { setMastersList } from '../Store/Slice/systemConfigurations.slice'
import { secondsToHMS } from './DurationHelper'
import {
  combineErrorMessage,
  getDataBasedOnkey,
  getDateInFormat,
  objectToQueryString,
} from './Util'

export const debounce = <T extends (...args: any[]) => void>(func: T, delay: number) => {
  let timeoutId: ReturnType<typeof setTimeout>

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay) // Ensure that the return type matches the function type
  }
}

const getFilterParams = (key: string, filters: any) => {
  let queryPramas: any = {
    limit: filters.limit,
    page: filters.pageNumber,
  }
  switch (key) {
    case 'role':
      queryPramas = {
        ...queryPramas,
        sortType: -1,
      }
      if (filters.search) {
        queryPramas.filterBy = 'roleName'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      }
      break
    case 'user':
      if (filters.search) {
        queryPramas.filterBy = 'userName'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      }
      break
    case 'partner':
      if (filters.search) {
        queryPramas.filterBy = 'serviceName'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      }

      break
    case 'bulkUpdate':
      if (filters.search) {
        queryPramas.filterBy = 'jobName'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      } else if (filters?.filters) {
        queryPramas.filterBy = filters?.filters[0]?.filterBy
        queryPramas.filterCondition = filters?.filters[0]?.filterCondition
        queryPramas.filterValue = filters?.filters[0]?.filterValue
      }
      break
    case 'attributeRepo':
    case 'assetDefination':
      queryPramas = {
        ...queryPramas,
        sortBy: 'createdAt',
        sortType: -1,
      }
      if (filters.search) {
        queryPramas.searchText = filters.search
      }
      break
    case 'geoPolicy':
      if (filters.search) {
        queryPramas.filterBy = 'policyName'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      }
      break
    case 'contentIngestionReport':
      queryPramas = {
        ...queryPramas,
        sortBy: 'createdAt',
        sortType: -1,
      }
      if (filters.search) {
        queryPramas.filterBy = 'requestId'
        queryPramas.filterCondition = 'contains'
        queryPramas.filterValue = filters.search
      }
      break
    default:
      break
  }
  if (filters.sort) {
    queryPramas.sortBy = filters.sort
    queryPramas.sortType = filters.sortType
  }
  return queryPramas
}

const getDaysFromKey = (days: any) => {
  return days
    .map((item: any) => {
      const daydata: any = dayOption.find((day: any) => day.value === item)
      return daydata?.key
    })
    .join(', ')
}

const getCountriesFromKey = (countryList: any, countries: any) => {
  if (countryList?.length) {
    return countries.map((item: any) => getDataBasedOnkey(countryList, 'value', item)).join(', ')
  } else {
    return ''
  }
}

// const filterModulesKeys = [
//   '/associated-assets_versionId_tab',
//   '/audit-trails',
//   '/homepage/',
//   '/advanced-search',
//   '/manage-content/',

//   '/manage-user',
//   '/manage-role',

//   '/metamaster/attribute-repo',
//   '/metamaster/assets-defination',
//   '/metamaster/geo-policy',
//   '/metamaster/masters/',

//   '/audit-logs/content-logs',
//   '/audit-logs/user-and-role-logs',
//   '/audit-logs/master-logs',
//   '/audit-logs/transcoding-logs',
// ]

/**
 *
 * @param pageKey page key string
 * @param savedFilterData saved filter
 * @returns
 * If key is from associated save all filters
 */
const clearOtherFiltersIfSelectedIsModule = (pageKey: string, savedFilterData: any) => {
  if (pageKey.includes('/associated-assets_')) {
    // check associated
    delete savedFilterData['/audit-trails']
    return savedFilterData
  } else if (pageKey.includes('/audit-trails')) {
    return savedFilterData
  }
  return {}
}

export const clearSavedFiltersByKey = (pageKey: string) => {
  let savedFilterData: any = localStorage.getItem('filterData')
  if (savedFilterData) {
    savedFilterData = JSON.parse(savedFilterData)
    if (savedFilterData[`${pageKey}`]) {
      delete savedFilterData[`${pageKey}`]
      localStorage.setItem('filterData', JSON.stringify(savedFilterData))
    }
  }
}

export const getSavedFilter = (pageKey: string) => {
  let savedFilterData: any = localStorage.getItem('filterData')
  if (savedFilterData) {
    savedFilterData = JSON.parse(savedFilterData)
    if (savedFilterData[`${pageKey}`]) {
      return savedFilterData[`${pageKey}`]
    }
  }
  return false
}

export const setSavedFilter = (pageKey: string, data: any) => {
  let savedFilterData: any = localStorage.getItem('filterData')
  if (savedFilterData) {
    savedFilterData = JSON.parse(savedFilterData)
    savedFilterData = clearOtherFiltersIfSelectedIsModule(pageKey, savedFilterData)
    localStorage.setItem('filterData', JSON.stringify({ ...savedFilterData, [pageKey]: data }))
    return
  }
  localStorage.setItem('filterData', JSON.stringify({ [pageKey]: data }))
}

export const onClickCommonListingRow = (params: {
  key: string
  row: Record<string, string | number | boolean | React.ReactElement | object>
  header?: any
  navigate?: any
}) => {
  let url
  switch (params.key) {
    case '/manage-user':
      url = `/manage-user/view/${params.row._id}`
      break
    case '/manage-role':
      url = `/manage-role/view/${params.row._id}`
      break
    case '/metamaster/attribute-repo':
      url = `/metamaster/attribute-repo/view/${params.row._id}`
      break
    case '/metamaster/assets-defination':
      url = `/metamaster/assets-defination/view/${params.row._id}`
      break
    case '/metamaster/geo-policy':
      url = `/metamaster/geo-policy/view/${params.row._id}`
      break
    case '/partners':
      url = `/partners/view/${params.row._id}`
      break
    case '/bulk-update':
      url = `/bulk-update/view/${params.row.jobId}`
      break
    case '/audit-logs/content-logs':
      url = `/audit-logs/content-logs/view/${params.row._id}`
      break
    case '/audit-logs/user-and-role-logs':
      url = `/audit-logs/user-and-role-logs/view/${params.row._id}`
      break
    case '/audit-logs/master-logs':
      url = `/audit-logs/master-logs/view/${params.row._id}`
      break
    case '/audit-logs/transcoding-logs':
      url = `/audit-logs/transcoding-logs/view/${params.row._id}`
      break
    case '/reports/content-ingestion-report': {
      const {
        blitzContentVersionId = '',
        assetType = '',
        contentCategory = '',
        assetCategory = '',
      } = (params?.row as any) || {}
      url = ''
      if (isIngestionRowClickable(params?.row)) {
        let pageType = CONSTANTS.PAGE_TYPE.VIEW
        let activeVersionId = blitzContentVersionId
        const versionsList: any = params?.row?.contentData
        if (versionsList?.length) {
          const isVersionActive = versionsList.find((element: any) => {
            element._id === blitzContentVersionId
          })
          if (!isVersionActive) {
            activeVersionId = versionsList[0]._id
          } else if (isVersionActive?.state === CONSTANTS.ASSET_STATUS.ARCHIEVED) {
            pageType = CONSTANTS.PAGE_TYPE.VIEW_ONLY
          }
        }
        url = `/asset/${pageType}/${activeVersionId}/${assetType}/${contentCategory}/${assetCategory}/${CONSTANTS.CATEGORIES.RIGHTS_AND_AVAILABILITY}`
      }
      break
    }
    default:
      break
  }
  if (url && params.navigate) {
    params.navigate(url)
  }
  return url
}

export const isIngestionRowClickable = (data: any = {}) => {
  const isValid = (value: string | undefined) => value && value !== 'NA'
  const {
    blitzContentVersionId = '',
    assetType = '',
    contentCategory = '',
    assetCategory = '',
    // requestedObj: { action = '' } = {},
    // blitzContentStatus = '',
  } = data
  return (
    // checkRowClickable(action as string, blitzContentStatus as string) &&
    isValid(blitzContentVersionId) &&
    isValid(assetType) &&
    isValid(contentCategory) &&
    isValid(assetCategory)
  )
}

export const onClickCommonListingBack = (params: { pageConfig: any; navigate: any }) => {
  if (params?.pageConfig?.key?.includes('/metamaster/masters/')) {
    const path = params.pageConfig.key.split('/')
    params.navigate(path.slice(0, path.length - 1).join('/'))
  }
}

export const fetchUserListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args

  const paramsData = objectToQueryString(getFilterParams('user', filters))

  dispatch(setLoader(true))
  const requestedData: any = USER_MANAGEMENT_CRUD.LIST_USER(paramsData)
  const result: any = await mainApiService(requestedData)

  if (result?.responseCode === 200) {
    const tempData: UserListResponseModal[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        userName: ele.userName,
        cyberarkId: ele.cyberarkId,
        userEmail: ele.userEmail,
        roleName: ele.roles?.roleName || 'None',
        createdAt: format(new Date(ele.createdAt.split('T')[0]), 'dd/MM/yyyy'),
        status: getStatus(ele.status === 'ACTIVE'),
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchPartnersListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const paramsData = objectToQueryString(getFilterParams('partner', filters))

  dispatch(setLoader(true))
  const requestedData: any = PARTNER_APIS.GET_PARTNER_LIST(paramsData)
  const result: any = await mainApiService(requestedData)
  if (result?.responseCode === 200) {
    const tempData: any[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        serviceName: ele.serviceName || 'NA',
        serviceAlias: ele.serviceAlias || 'NA',
        contractStartDate: ele.contractStartDate
          ? format(new Date(ele.contractStartDate), CONSTANTS.DATE_FORMAT_TIME)
          : 'NA',
        contractEndDate: ele.contractEndDate
          ? format(new Date(ele.contractEndDate), CONSTANTS.DATE_FORMAT_TIME)
          : 'NA',
        contactEmail: ele.contactEmail || 'NA',
        contactPhone: ele.contactPhone || 'NA',
        allowedAssetTypes: ele?.allowedAssetTypes,
        allowedMasters: ele?.allowedMasters,
        status: getStatus(ele.status === 'ACTIVE'),
      })
    })

    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchBulkUploadListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const payload: any = {
    limit: filters.limit,
    page: filters.pageNumber,
    jobFilters: [],
  }
  if (filters?.filters || filters?.state) {
    if (filters?.filters) {
      filters?.filters?.forEach((item: any) => {
        payload.jobFilters.push(item)
      })
    }
    if (filters?.state?.length) {
      payload.jobFilters.push({
        filterBy: 'status',
        filterValue: filters?.state.length > 1 ? filters?.state : filters?.state[0],
        filterCondition: filters?.state.length > 1 ? 'in' : 'equals',
      })
    }
  }
  if (filters.search) {
    payload.titleOrId = filters.search
  }
  if (filters.sort && filters.sortType) {
    payload.sortBy = filters.sort
    payload.sortType = Number(filters.sortType)
  }
  dispatch(setLoader(true))
  const requestedData: any = BULK_UPDATE_APIS.GET_JOB_LIST(payload)
  const result: any = await mainApiService(requestedData)
  // const result = BULK_UPDATE_CONSTENT.LISTING_API_RESPONSE
  if (result?.responseCode === 200) {
    const tempData: any[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        _id: ele._id,
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        jobId: ele.jobId || 'NA',
        jobName: ele.jobName || 'NA',
        assetCount: ele.assetCount || 'NA',
        userName: ele.userName || 'NA',
        scheduleDate: ele.scheduleDate,
        endDate: ele.endDate,
        successCount: ele.successCount || 'NA',
        failureCount: ele.failureCount || 'NA',
        status: getJobStatus(ele.status),
        action: bulkUpdateListingAction(ele, dispatch),
      })
    })

    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchPartnerInformation = async (args: any): Promise<ListAPIDefinationModal> => {
  const { dispatch, setLoader, showAlert, pageConfig } = args

  dispatch(setLoader(true))
  const result = {
    responseCode: 200,
    data: {
      count: 1,
      records: [
        {
          _id: '1',
          serviceName: 'Partner Inc.',
          serviceAlias: 'PI',
          contractStartdate: '2023-01-01T00:00:00Z',
          contractEnddate: '2025-01-01T00:00:00Z',
          contactEmail: 'contact@partner.com',
          contactPhone: '123-456-7890',
          status: 'ACTIVE',
        },
      ],
    },
  }

  if (result?.responseCode === 200) {
    const tempData: any[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        serviceName: ele.serviceName,
        serviceAlias: ele.serviceAlias,
        contactEmail: ele.contactEmail,
        contactPhone: ele.contactPhone,
        contractStartdate: format(new Date(ele.contractStartdate.split('T')[0]), 'dd/MM/yyyy'),
        contractEnddate: format(new Date(ele.contractEnddate.split('T')[0]), 'dd/MM/yyyy'),
        status: getStatus(ele.status),
      })
    })

    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchRoleListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args

  const paramsData = objectToQueryString(getFilterParams('role', filters))

  dispatch(setLoader(true))
  const requestedData: any = ROLE_MANAGEMENT_CRUD.LIST_ROLE(paramsData)
  const result: any = await mainApiService(requestedData)
  if (result?.responseCode === 200) {
    const tempData: RoleListResponseModal[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        roleName: ele.roleName,
        associatedUsers: ele.associatedUsers,
        createdAt: getDateInFormat(ele.createdAt as string, 'dd/MM/yyyy'),
        updatedAt: getDateInFormat(ele.updatedAt as string, 'dd/MM/yyyy'),
        status: getStatus(ele?.status === 'ACTIVE'),
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, combineErrorMessage(result), 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchAttributeRepoListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const paramsData = objectToQueryString(getFilterParams('attributeRepo', filters))
  dispatch(setLoader(true))
  const requestedData: any = ATTRIBUTE_REPOSITORY_CRUD.LIST_ATTRIBUTE_REPO(paramsData)
  const result: any = await mainApiService(requestedData)
  if (result?.responseCode === 200) {
    const tempData: AttributeRepoListResponseModal[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele?._id,
        label: ele?.label,
        fieldType: ele?.fieldType,
        dataType: ele?.dataType,
        fieldName: ele?.fieldName,
        isRequired: ele?.isRequired ? 'Yes' : 'No',
        isB2B: ele?.isB2B ? 'Yes' : 'No',
        status: getStatus(ele.status === 'ACTIVE'),
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, result?.message || result?.error?.message, 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchAssetDefinationListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const paramsData = objectToQueryString(getFilterParams('assetDefination', filters))
  dispatch(setLoader(true))
  const requestedData: any = ASSET_TYPE_DEFINITION_CRUD.LIST_ASSET_TYPE(paramsData)
  const result: any = await mainApiService(requestedData)
  if (result?.responseCode === 200) {
    const tempData: AssetDefinationListResponseModal[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele?._id,
        assetType: ele?.assetType,
        assetCategory: ele?.assetCategory,
        contentCategory: ele?.contentCategory,
        haveAllowedParents: ele?.haveAllowedParents ? 'Yes' : 'No',
        status: getStatus(ele.status === 'ACTIVE'),
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, result?.message || result?.error?.message, 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchGeoPolicyListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const paramsData = objectToQueryString(getFilterParams('geoPolicy', filters))
  dispatch(setLoader(true))
  const [result, countryList]: any = await Promise.all([
    mainApiService(GEO_POLICY_CRUD.LIST_GEO_POLICY(paramsData)),
    mainApiService(GEO_POLICY_CRUD.GET_COUNTRY()),
  ])
  const tempCountry: any[] = []
  if (countryList?.data?.records?.length) {
    countryList.data.records.forEach((ele: any) => {
      tempCountry.push({ key: ele.key, value: ele.value })
    })
  }
  if (result?.responseCode === 200) {
    const tempData: GeoPolicyListResponseModal[] = []
    result?.data?.records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele?._id,
        policyName: ele?.policyName,
        description: ele?.description,
        timeDifference: secondsToHMS(ele?.timeDifference),
        countries: getCountriesFromKey(tempCountry, ele?.countries),
        days: getDaysFromKey(ele?.days),
        status: getStatus(ele?.status === 'ACTIVE'),
        geoPolicyGroupName: ele?.geoPolicyGroupName || 'NA',
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, result?.message || result?.error?.message, 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

export const fetchAuditTrailListing = async (args: any) => {
  const { filters, dispatch, setLoader, pageConfig, previousFilter } = args

  const reqData: any = {
    type: pageConfig?.type,
    page: filters?.pageNumber ?? 1,
    limit: filters?.limit ?? 10,
    searchFilters: [],
  }
  if (filters?.filters) {
    const uniqueFilters = Object.values(
      filters?.filters.reduce((acc: any, filter: any) => {
        acc[filter.filterBy] = filter
        return acc
      }, {} as any),
    )
    reqData.searchFilters = [...reqData.searchFilters, ...uniqueFilters]
  }
  if (filters?.fromDate) {
    reqData.fromDate = filters.fromDate
  }

  if (filters?.toDate) {
    reqData.toDate = filters.toDate
  }

  if (filters?.contentId) {
    reqData.contentId = filters.contentId
  }

  if (filters?.addArchivedData) {
    reqData.addArchivedData = filters.addArchivedData
  }

  if (filters?.sortBy) {
    reqData.sortBy = filters.sortBy
    reqData.sortType = filters.sortType
  }

  if (filters?.userName) {
    const userFilter = {
      filterBy: 'userName',
      filterValue: filters.userName,
      filterCondition: 'contains',
    }
    reqData.searchFilters.push(userFilter)
  }

  try {
    dispatch(setLoader(true))
    const requestData: any = AUDIT_LOGS.LIST_USER_ROLE_LOG(reqData)
    const response = await mainApiService(requestData)
    const records = response?.data || {}

    if (records?.records) {
      const tempData = getListingForAuditTrailsBasedOnType(records.records, pageConfig)
      dispatch(setLoader(false))
      return {
        count: records?.count,
        records: tempData,
      }
    }
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  } catch (error) {
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}
const capitalizeFirstLetterOfEachWord = (str: string): string => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}

export const fetchMastersListing = async (args: any): Promise<ListAPIDefinationModal> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig, navigate, isWritePermission } = args
  const paramsData = objectToQueryString(getFilterParams('geoPolicy', filters))
  dispatch(setLoader(true))
  dispatch(clearSystemConfigurationStore({}) as any)
  const url = `/attributeRepository/${
    pageConfig.isDefaultMaster ? pageConfig.pageName : 'systemConfigurations'
  }/action/list${pageConfig.isDefaultMaster ? `?${paramsData}` : `?keyName=${pageConfig.pageName}`}`
  const result: any = await mainApiService({ url, method: 'get' })
  if (result?.responseCode === 200) {
    let tempData: MasterListResponseModal[] | MasterSysConfigListResponseModal[] = []
    const recordsList = result?.data?.records || result?.data
    recordsList.forEach((ele: any) => {
      if (pageConfig.isDefaultMaster) {
        tempData = tempData as MasterListResponseModal[]
        tempData.push({
          rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
          ...ele,
          createdBy: ele?.createdBy?.userEmail,
          updatedBy: ele?.updatedBy?.userEmail,
          status: getStatus(ele.status === 'ACTIVE'),
          action: isWritePermission
            ? commonListingEditBtn(dispatch, navigate, ele, `${pageConfig.key}/edit/${ele?._id}`)
            : 'NA',
        })
      } else {
        tempData = tempData as MasterSysConfigListResponseModal[]
        tempData.push({
          rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
          ...ele,
          pageName: pageConfig.pageName,
          url: pageConfig.key,
          name: capitalizeFirstLetterOfEachWord(pageConfig.pageTitle),
          action: isWritePermission
            ? commonListingEditBtn(dispatch, navigate, ele, `${pageConfig.key}/edit/${ele?._id}`)
            : 'NA',
        })
      }
    })
    if (!pageConfig.isDefaultMaster && !tempData.length) {
      dispatch(
        setMastersList([
          {
            pageName: pageConfig.pageName,
            url: pageConfig.key,
            name: capitalizeFirstLetterOfEachWord(pageConfig.pageTitle),
          },
        ]),
      )
    } else {
      dispatch(setMastersList(tempData))
    }
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, result?.message || result?.error?.message, 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}

const getListingForAuditTrailsBasedOnType = (records: any, pageConfig: any) => {
  const type = pageConfig?.['type']
  const tempData: any[] = []
  if (type === 'content') {
    records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        contentId: ele.assetId,
        event: ele.event,
        contentVersionId: ele?.contentVersionId || 'NA',
        activity: ele.activity,
        assetState:
          ele.assetState === CONSTANTS.ASSET_STATUS.CREATED
            ? CONSTANTS.ASSET_STATE_DISPLAY.CREATED
            : ele.assetState,
        userName: ele.userName,
        roleName: ele.roleName,
        createdAt: getDateInFormat(ele.createdAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        updatedAt: getDateInFormat(ele.updatedAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        blitzTransactionId: ele.blitzTransactionId,
      })
    })
  }

  if (type === 'user_role') {
    records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        subType: ele.subType,
        event: ele.event,
        activity: ele.activity,
        userName: ele.userName,
        roleName: ele.roleName,
        createdAt: getDateInFormat(ele.createdAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        updatedAt: getDateInFormat(ele.updatedAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        blitzTransactionId: ele.blitzTransactionId,
      })
    })
  }

  if (type === 'master') {
    records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        subType: ele.subType,
        event: ele.event,
        activity: ele.activity,
        userName: ele.userName,
        roleName: ele.roleName,
        createdAt: getDateInFormat(ele.createdAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        updatedAt: getDateInFormat(ele.updatedAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        blitzTransactionId: ele.blitzTransactionId,
      })
    })
  }

  if (type === 'transcoding') {
    records?.forEach((ele: any) => {
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: ele }),
        _id: ele._id,
        contentId: ele.assetId,
        event: ele.event,
        userName: ele.userName,
        roleName: ele.roleName,
        createdAt: getDateInFormat(ele.createdAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        updatedAt: getDateInFormat(ele.updatedAt as string, CONSTANTS.DATE_N_TIME_FORMAT_SECONDS),
        blitzTransactionId: ele.blitzTransactionId,
      })
    })
  }

  return tempData
}

export const checkIsPartnerParentsFailed = (element: any, rowsData: any) => {
  if (element?.assetCategory === 'GOB' || element?.assetCategory === 'BUNDLE') {
    return false
  }
  const isFailed = rowsData.filter((item: any) => {
    return (
      item.requestId === element.requestId &&
      (item?.assetCategory === 'GOB' || item?.assetCategory === 'BUNDLE') &&
      (item?.blitzContentStatus === 'FAILURE' || item?.blitzContentStatus === 'VALIDATION_FAILED')
    )
  })
  return isFailed.length > 0
}

export const checkIsPartnerContentItemFailed = (element: any) => {
  return (
    (element?.blitzContentStatus === 'SUCCESS' ||
      element?.blitzContentStatus === 'FAILURE' ||
      element?.blitzContentStatus === 'VALIDATION_FAILED') &&
    element?.assetCategory === 'CONTENT_ITEM'
  )
}

/**
 * Commenting this function as currently not in use
 *
const checkRowClickable = (requestAction: string, blitzContentStatus: string) => {
  let result = false
  if (
    requestAction === 'CREATE' &&
    ['CREATED', 'INITIATED', 'IN_QUEUE', 'FAILURE'].includes(blitzContentStatus)
  ) {
    result = false
  }
  if (
    requestAction === 'CREATE' &&
    ['PUBLISHED', 'DEPUBLISHED', 'VALIDATION_FAILED'].includes(blitzContentStatus)
  ) {
    result = true
  }
  if (
    requestAction === 'UPDATE' &&
    [
      'CREATED',
      'INITIATED',
      'IN_QUEUE',
      'FAILURE',
      'PUBLISHED',
      'DEPUBLISHED',
      'VALIDATION_FAILED',
    ].includes(blitzContentStatus)
  ) {
    result = true
  }
  if (
    requestAction === 'DELETE' &&
    ['CREATED', 'INITIATED', 'IN_QUEUE', 'FAILURE', 'PUBLISHED', 'DEPUBLISHED'].includes(
      blitzContentStatus,
    )
  ) {
    result = true
  }
  return result
}
 */

export const fetchContentIngestionListing = async (args: any): Promise<any> => {
  const { filters, dispatch, setLoader, showAlert, pageConfig } = args
  const payload: any = {
    limit: filters.limit,
    page: filters.pageNumber,
    inboundFilters: [],
  }
  if (filters?.filters) {
    if (filters?.filters) {
      filters?.filters?.forEach((item: any) => {
        if (item.filterBy === 'blitzContentId') {
          payload.inboundFilters.push({ ...item, filterValue: Number(item.filterValue) })
        } else {
          payload.inboundFilters.push(item)
        }
      })
    }
  }
  if (filters.search) {
    payload.requestId = filters.search
  }
  if (filters.sort && filters.sortType) {
    payload.sortBy = filters.sort
    payload.sortType = Number(filters.sortType)
  }
  const requestedData: any = PARTNER_APIS.GET_INJUSTION_REPORT(payload)
  dispatch(setLoader(true))
  const result: any = await mainApiService(requestedData)
  if (result?.responseCode === 200) {
    const rowsData = (result?.data?.records || result?.data) ?? []
    const tempData: any = []
    rowsData?.forEach((element: any) => {
      const wweFieldsData: any = {
        assetType: element?.assetType ?? 'NA',
        assetCategory: element?.assetCategory ?? 'NA',
        contentCategory: element?.contentCategory ?? 'Sports',
        jobType: element?.jobType ?? 'NA',
        blitzContentVersionId: element?.blitzContentVersionId ?? 'NA',
        blitzContentId: element?.blitzContentId ?? 'NA',
        title: element?.title ?? 'NA',
        contentId: element?.publishedContentId ?? 'NA',
        assetTitle: element?.assetTitle ?? 'NA',
        action: commonListingReportActionBtn(dispatch, element, 'Re-trigger', rowsData),
        blitzContentStatus: element?.blitzContentStatus ?? 'NA',
        isRowClickable: isIngestionRowClickable(element),
      }
      tempData.push({
        rowURL: onClickCommonListingRow({ key: pageConfig.key, row: element }),
        ...element,
        ...wweFieldsData,
        partnerContentId: element?.partnerContentId || 'NA',
        partnerRequestTimestamp: element?.partnerRequestTimestamp
          ? element?.partnerRequestTimestamp
          : 'NA',
        details: commonListingReportActionBtn(dispatch, element, 'Details', rowsData),
      })
    })
    dispatch(setLoader(false))
    return {
      count: result?.data?.count,
      records: tempData,
    }
  } else {
    dispatch(showAlert([true, result?.message || result?.error?.message, 'error']))
    dispatch(setLoader(false))
    return {
      count: 0,
      records: [],
    }
  }
}
