const PlayListPlay = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M4.16659 8.33333H12.4999C12.9583 8.33333 13.3333 8.70833 13.3333 9.16667C13.3333 9.625 12.9583 10 12.4999 10H4.16659C3.70825 10 3.33325 9.625 3.33325 9.16667C3.33325 8.70833 3.70825 8.33333 4.16659 8.33333ZM4.16659 5H12.4999C12.9583 5 13.3333 5.375 13.3333 5.83333C13.3333 6.29167 12.9583 6.66667 12.4999 6.66667H4.16659C3.70825 6.66667 3.33325 6.29167 3.33325 5.83333C3.33325 5.375 3.70825 5 4.16659 5ZM4.16659 11.6667H9.16659C9.62492 11.6667 9.99992 12.0417 9.99992 12.5C9.99992 12.9583 9.62492 13.3333 9.16659 13.3333H4.16659C3.70825 13.3333 3.33325 12.9583 3.33325 12.5C3.33325 12.0417 3.70825 11.6667 4.16659 11.6667ZM11.6666 12.4V15.925C11.6666 16.25 12.0166 16.45 12.2999 16.2833L15.2416 14.5167C15.5083 14.3583 15.5083 13.9667 15.2416 13.8L12.2999 12.0333C12.0166 11.875 11.6666 12.075 11.6666 12.4Z'
        fill='#402080'
      />
    </svg>
  )
}

export default PlayListPlay
export const PlayMediaIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M7.16665 5.6669V10.3336C7.16665 10.6069 7.47999 10.7669 7.69999 10.6002L10.8133 8.2669C10.9933 8.13356 10.9933 7.8669 10.8133 7.73356L7.69999 5.40023C7.47999 5.23356 7.16665 5.39356 7.16665 5.6669ZM7.83332 2.15356C7.83332 1.7269 7.43999 1.40023 7.02665 1.49356C6.27999 1.6669 5.57332 1.96023 4.94665 2.36023C4.59332 2.5869 4.53999 3.09356 4.83999 3.39356C5.05332 3.6069 5.39332 3.66023 5.64665 3.50023C6.15999 3.17356 6.72665 2.93356 7.33999 2.80023C7.63332 2.73356 7.83332 2.46023 7.83332 2.15356ZM3.89999 4.34023C3.59332 4.04023 3.09332 4.0869 2.86665 4.4469C2.46665 5.07356 2.17332 5.78023 1.99999 6.5269C1.90665 6.94023 2.22665 7.33356 2.65332 7.33356C2.95332 7.33356 3.23332 7.13356 3.29332 6.84023C3.42665 6.23356 3.67332 5.66023 3.99332 5.15356C4.16665 4.89356 4.11332 4.55356 3.89999 4.34023ZM2.65332 8.6669C2.22665 8.6669 1.89999 9.06023 1.99332 9.47356C2.16665 10.2202 2.45999 10.9202 2.85999 11.5536C3.08665 11.9136 3.59332 11.9602 3.89332 11.6602C4.10665 11.4469 4.15999 11.1069 3.99332 10.8536C3.66665 10.3469 3.42665 9.78023 3.29332 9.1669C3.23332 8.8669 2.95999 8.6669 2.65332 8.6669ZM4.94665 13.6336C5.57999 14.0336 6.27999 14.3269 7.02665 14.5002C7.43999 14.5936 7.83332 14.2669 7.83332 13.8469C7.83332 13.5469 7.63332 13.2669 7.33999 13.2069C6.73332 13.0736 6.15999 12.8269 5.65332 12.5069C5.39332 12.3469 5.05999 12.3936 4.84665 12.6136C4.53999 12.9069 4.58665 13.4069 4.94665 13.6336ZM15.1667 8.00023C15.1667 11.1536 12.9667 13.8069 10.0133 14.4936C9.59999 14.5936 9.19999 14.2669 9.19999 13.8402C9.19999 13.5336 9.40665 13.2669 9.69999 13.1936C12.0667 12.6469 13.8333 10.5269 13.8333 8.00023C13.8333 5.47356 12.0667 3.35356 9.69999 2.8069C9.40665 2.74023 9.19999 2.4669 9.19999 2.16023C9.19999 1.73356 9.59999 1.4069 10.0133 1.5069C12.9667 2.19356 15.1667 4.8469 15.1667 8.00023Z'
        fill='#402080'
      />
    </svg>
  )
}
export const EditSolidIcon = ({ title = '' }: any) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_4314_34155)'>
        <path
          d='M2 11.6397V13.6664C2 13.853 2.14667 13.9997 2.33333 13.9997H4.36C4.44667 13.9997 4.53333 13.9664 4.59333 13.8997L11.8733 6.62638L9.37333 4.12638L2.1 11.3997C2.03333 11.4664 2 11.5464 2 11.6397ZM13.8067 4.69305C14.0667 4.43305 14.0667 4.01305 13.8067 3.75305L12.2467 2.19305C11.9867 1.93305 11.5667 1.93305 11.3067 2.19305L10.0867 3.41305L12.5867 5.91305L13.8067 4.69305Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_4314_34155'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const CloseRedIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <g clipPath='url(#clip0_4314_34152)'>
        <path
          d='M-1 1V15C-1 16.1 -0.1 17 1 17H15C16.1 17 17 16.1 17 15V1C17 -0.1 16.1 -1 15 -1H1C-0.1 -1 -1 -0.1 -1 1ZM12.3 12.29C11.91 12.68 11.28 12.68 10.89 12.29L8 9.41L5.11 12.3C4.72 12.69 4.09 12.69 3.7 12.3C3.31 11.91 3.31 11.28 3.7 10.89L6.59 8L3.7 5.11C3.31 4.72 3.31 4.09 3.7 3.7C4.09 3.31 4.72 3.31 5.11 3.7L8 6.59L10.89 3.71C11.28 3.32 11.91 3.32 12.3 3.71C12.69 4.1 12.69 4.73 12.3 5.12L9.41 8L12.3 10.88C12.68 11.27 12.68 11.91 12.3 12.29Z'
          fill='#D4351C'
        />
      </g>
      <defs>
        <clipPath id='clip0_4314_34152'>
          <rect width='24' height='24' fill='white' transform='translate(-4 -4)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const DownloadIcon = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='28' height='28' rx='4' fill='#ECE9F2' />
      <g clipPath='url(#clip0_23796_19411)'>
        <path
          d='M17.0599 12H15.9999V8.66667C15.9999 8.3 15.6999 8 15.3333 8H12.6666C12.2999 8 11.9999 8.3 11.9999 8.66667V12H10.9399C10.3466 12 10.0466 12.72 10.4666 13.14L13.5266 16.2C13.7866 16.46 14.2066 16.46 14.4666 16.2L17.5266 13.14C17.9466 12.72 17.6533 12 17.0599 12ZM9.33325 18.6667C9.33325 19.0333 9.63325 19.3333 9.99992 19.3333H17.9999C18.3666 19.3333 18.6666 19.0333 18.6666 18.6667C18.6666 18.3 18.3666 18 17.9999 18H9.99992C9.63325 18 9.33325 18.3 9.33325 18.6667Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_23796_19411'>
          <rect width='16' height='16' fill='white' transform='translate(6 6)' />
        </clipPath>
      </defs>
    </svg>
  )
}
export const BulkUploadCancelIcon = () => {
  return (
    <svg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='28' height='28' rx='4' fill='#FBEBE8' />
      <g clipPath='url(#clip0_23796_26904)'>
        <g clipPath='url(#clip1_23796_26904)'>
          <g clipPath='url(#clip2_23796_26904)'>
            <path
              d='M5 7V21C5 22.1 5.9 23 7 23H21C22.1 23 23 22.1 23 21V7C23 5.9 22.1 5 21 5H7C5.9 5 5 5.9 5 7ZM18.3 18.29C17.91 18.68 17.28 18.68 16.89 18.29L14 15.41L11.11 18.3C10.72 18.69 10.09 18.69 9.7 18.3C9.31 17.91 9.31 17.28 9.7 16.89L12.59 14L9.7 11.11C9.31 10.72 9.31 10.09 9.7 9.7C10.09 9.31 10.72 9.31 11.11 9.7L14 12.59L16.89 9.71C17.28 9.32 17.91 9.32 18.3 9.71C18.69 10.1 18.69 10.73 18.3 11.12L15.41 14L18.3 16.88C18.68 17.27 18.68 17.91 18.3 18.29Z'
              fill='#D4351C'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_23796_26904'>
          <rect x='6' y='6' width='16' height='16' rx='2' fill='white' />
        </clipPath>
        <clipPath id='clip1_23796_26904'>
          <rect width='24' height='24' fill='white' transform='translate(2 2)' />
        </clipPath>
        <clipPath id='clip2_23796_26904'>
          <rect width='24' height='24' fill='white' transform='translate(2 2)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export const CalenderPurpleIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <g clipPath='url(#clip0_4455_22871)'>
        <path
          d='M7.49964 6.00007H6.49964V5.00007C6.49964 4.72507 6.27464 4.50007 5.99964 4.50007C5.72464 4.50007 5.49964 4.72507 5.49964 5.00007V6.00007H4.49964C4.22464 6.00007 3.99964 6.22507 3.99964 6.50007C3.99964 6.77507 4.22464 7.00007 4.49964 7.00007H5.49964V8.00007C5.49964 8.27507 5.72464 8.50007 5.99964 8.50007C6.27464 8.50007 6.49964 8.27507 6.49964 8.00007V7.00007H7.49964C7.77464 7.00007 7.99964 6.77507 7.99964 6.50007C7.99964 6.22507 7.77464 6.00007 7.49964 6.00007ZM10.5896 2.50507L9.04964 1.22507C8.83964 1.05007 8.52464 1.07507 8.34464 1.29007C8.16964 1.50007 8.19964 1.81507 8.40964 1.99507L9.94464 3.27507C10.1546 3.45007 10.4696 3.42507 10.6496 3.21007C10.8296 3.00007 10.7996 2.68507 10.5896 2.50507ZM2.04964 3.27507L3.58464 1.99507C3.79964 1.81507 3.82964 1.50007 3.64964 1.29007C3.47464 1.07507 3.15964 1.05007 2.94964 1.22507L1.40964 2.50507C1.19964 2.68507 1.16964 3.00007 1.34964 3.21007C1.52464 3.42507 1.83964 3.45007 2.04964 3.27507ZM5.99964 2.00007C3.51464 2.00007 1.49964 4.01507 1.49964 6.50007C1.49964 8.98507 3.51464 11.0001 5.99964 11.0001C8.48464 11.0001 10.4996 8.98507 10.4996 6.50007C10.4996 4.01507 8.48464 2.00007 5.99964 2.00007ZM5.99964 10.0001C4.06964 10.0001 2.49964 8.43007 2.49964 6.50007C2.49964 4.57007 4.06964 3.00007 5.99964 3.00007C7.92964 3.00007 9.49964 4.57007 9.49964 6.50007C9.49964 8.43007 7.92964 10.0001 5.99964 10.0001Z'
          fill='#402080'
        />
      </g>
      <defs>
        <clipPath id='clip0_4455_22871'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
