import MUITooltip from '../Shared/MuiTooltipComponent'

function CirclePlus({ title = '' }: any) {
  return (
    <MUITooltip title={title} disableInteractive={true}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
      >
        <path
          d='M12.3693 7C11.8048 7 11.343 7.45 11.343 8V11H8.26413C7.69968 11 7.23785 11.45 7.23785 12C7.23785 12.55 7.69968 13 8.26413 13H11.343V16C11.343 16.55 11.8048 17 12.3693 17C12.9337 17 13.3955 16.55 13.3955 16V13H16.4744C17.0388 13 17.5007 12.55 17.5007 12C17.5007 11.45 17.0388 11 16.4744 11H13.3955V8C13.3955 7.45 12.9337 7 12.3693 7ZM12.3693 2C6.70418 2 2.10645 6.48 2.10645 12C2.10645 17.52 6.70418 22 12.3693 22C18.0343 22 22.6321 17.52 22.6321 12C22.6321 6.48 18.0343 2 12.3693 2ZM12.3693 20C7.84336 20 4.15901 16.41 4.15901 12C4.15901 7.59 7.84336 4 12.3693 4C16.8952 4 20.5795 7.59 20.5795 12C20.5795 16.41 16.8952 20 12.3693 20Z'
          fill='#858789'
        />
      </svg>
    </MUITooltip>
  )
}

export default CirclePlus
