import { SortByAlpha } from '@mui/icons-material'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import LastPageIcon from '@mui/icons-material/LastPage'
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { CONSTANTS } from '../../../Constants/Constant'
import { formatListingDate } from '../../../Helpers/ManageContent'
import { getStickyIndex } from '../../../Helpers/Util'
import { CommonTableHeaderModel, CommonTableModel } from '../../../Models/CommonTable'
import { TooltipText } from '../../TooltipText/TooltipText'
import MUIGrid from '../MUI-Grid'
import MUIPagination from '../MUI-Pagination'
import { DescendingIcon } from '../MUI-Table/MUI-Table.svgicons'
import MUITooltip from '../MuiTooltipComponent'
import SimpleDropdown from '../Simpledropdown'
import { FilterSortIcon, FilterUi } from './Helpers'
import css from './index.module.scss'

const getObjectValue = (obj: Record<string, any>, key: string): any => {
  const keys = key.split('.')
  return keys.reduce((acc, currentKey) => (acc ? acc[currentKey] : undefined), obj)
}

const MUICommonTable: React.FC<CommonTableModel> = ({
  headers,
  defaultValue = 'NA',
  rowsClickable = true,
  rows,
  childRows,
  className = '',
  noRecordsMessage = CONSTANTS.MESSAGES.NO_DATA_FOUND,
  sortFilterKey = 'id',
  selectedFilters = { sort: '', sortType: '', filters: [] },
  noBoxShadow,
  onChangeSort,
  onChangeFilter,
  onClearSortFilters,
  onClickRow,
  transparent = false,
  pagination = {
    show: false,
  } as any,
  filterConOptions = [
    { label: 'Is equal to', value: 'equals' },
    { label: 'Is not equal to', value: 'notEquals' },
    { label: 'Starts With', value: 'startsWith' },
    { label: 'Ends With', value: 'endsWith' },
    { label: 'Contains', value: 'contains' },
  ],
  archived = false,
  showArchivalHistory = false,
  onChangeArchivalState = () => {
    return
  },
  loader = false,
  sticky,
  stickyWidth,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [sortFilterOpen, setSortFilterOpen] = useState<string | number | boolean>('')
  const sortOptions = [
    { lable: 'Ascending', value: 1, icon: <SortByAlpha style={{ fontSize: '18px' }} /> },
    { lable: 'Descending', value: -1, icon: <DescendingIcon /> },
  ]
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [filterOpen, setFilterOpen] = useState<string | number | boolean>('')
  const [filterValue, setFilterValue] = useState<{
    filterBy: string | number
    filterValue: string | number
    filterCondition: string | number
  }>({ filterBy: '', filterValue: '', filterCondition: '' })

  const handleSortFilterOpen = (ev: any, header: CommonTableHeaderModel) => {
    setSortFilterOpen(header.id)
    setAnchorEl(ev.currentTarget)
  }

  const handleFilterOpen = (ev: any, header: CommonTableHeaderModel) => {
    setFilterOpen(header.id)
    setAnchorEl2(ev.currentTarget)
    const selectedFilter = selectedFilters?.filters?.find(
      (item) => item.filterBy === header[sortFilterKey],
    )
    if (selectedFilter) {
      setFilterValue({ ...selectedFilter })
    }
  }

  const handleSortFilterClose = (header?: any, value?: number) => {
    setSortFilterOpen('')
    setAnchorEl(null)
    if (header && onChangeSort) {
      onChangeSort(
        selectedFilters?.sort === header[sortFilterKey] &&
          selectedFilters?.sortType === String(value)
          ? { ...header, fieldName: '' }
          : header,
        value,
      )
    }
  }

  const handleFilterClose = (header?: any, value?: any) => {
    handleSortFilterClose()
    setFilterOpen('')
    setAnchorEl2(null)
    if (header && onChangeFilter) {
      onChangeFilter(header, { ...value, filterBy: header[sortFilterKey] })
    }
    setFilterValue({ filterBy: '', filterValue: '', filterCondition: '' })
  }

  const renderTableValue = (row: Record<string, any>, header: Record<string, any>) => {
    const value = getObjectValue(row, header.id)
    if (value && header?.fieldType === CONSTANTS.FIELDTYPES.DATETIME) {
      return formatListingDate(value, CONSTANTS.DATE_FORMAT_TIME)
    } else if (value && header?.fieldType === CONSTANTS.FIELDTYPES.DATE) {
      return formatListingDate(value, CONSTANTS.DATE_FORMAT)
    } else if (header?.dataType === CONSTANTS.FIELDTYPES.BOOLEAN) {
      return value === true ? 'Yes' : value === false ? 'No' : 'NA'
    } else if (React.isValidElement(value)) {
      return <span>{value}</span>
    } else {
      switch (typeof value) {
        case 'string':
        case 'number':
        case 'boolean':
          if (typeof value === 'string' || typeof value === 'number') {
            return <TooltipText text={value.toString()} length={25} />
          } else {
            return <span>{value}</span>
          }
        default:
          if (
            header?.isMultiple &&
            Array.isArray(value) &&
            value.length &&
            typeof value[0] === 'string'
          ) {
            return <TooltipText text={value.join(', ')} length={25} />
          }
          return defaultValue
      }
    }
  }

  const sortFilterUi = (header: CommonTableHeaderModel) => {
    const isFilter = selectedFilters?.filters?.find(
      (item: any) => item?.filterBy === header[sortFilterKey] && header.filter,
    )
    const isFilterEnabled = Boolean(selectedFilters?.sort === header[sortFilterKey] || isFilter)
    const clearSortBy = async () => {
      setSortFilterOpen('')
      setAnchorEl(null)
      setAnchorEl2(null)
      if (isFilterEnabled) {
        onClearSortFilters?.(
          header,
          header.filter
            ? {
                filterValue: '',
                filterCondition: 'equals',
                filterBy: header[sortFilterKey],
              }
            : null,
        )
      }
    }

    return sortFilterOpen === header.id ? (
      <Menu
        className='border-12 c-sort'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleSortFilterClose()}
      >
        {' '}
        {header.sort && (
          <>
            <div className='d-flex justify-content-between align-items-center'>
              <Typography>Sort By</Typography>
              {onClearSortFilters && (
                <span
                  className={isFilterEnabled ? 'clear-all' : 'clear-all-disabled'}
                  data-testid='clear-all-sort'
                  onClick={() => {
                    clearSortBy()
                  }}
                >
                  Clear Filter
                </span>
              )}
            </div>
            <RadioGroup
              aria-label='options'
              name='options'
              value={
                selectedFilters.sort === header[sortFilterKey] ? selectedFilters.sortType : null
              }
            >
              {sortOptions.map((sortOption, sortIndex) => (
                <MenuItem
                  key={sortOption.lable + '_' + sortIndex}
                  sx={{
                    paddingLeft: 0,
                  }}
                  className='d-flex justify-content-between align-items-center'
                  onClick={() => {
                    handleSortFilterClose(header, sortOption.value)
                  }}
                  disabled={header?.fieldType === CONSTANTS.FIELDTYPES.SELECT && header?.isMultiple}
                >
                  <FormControlLabel
                    labelPlacement='start'
                    value={sortOption.value}
                    label={
                      <div className='d-flex w-100 align-item-center justify-content-center'>
                        <div className='d-flex align-items-center'>{sortOption.icon}</div>
                        <div className='ml-8p'>
                          <span className='font-12p'>{sortOption.lable}</span>
                        </div>
                      </div>
                    }
                    control={<Radio className='ml-auto' />}
                  />
                </MenuItem>
              ))}
            </RadioGroup>
          </>
        )}
        {header.filter && (
          <div
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              handleFilterOpen(event, header)
            }}
          >
            <MenuItem
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                paddingLeft: 0,
                paddingRight: 0,
              }}
              className='d-flex justify-content-between align-items-center'
            >
              <MUIGrid style={{ display: 'flex', alignItems: 'center' }}>
                <FilterListOutlinedIcon />
                <span
                  className='font-12p interFont'
                  style={{ fontWeight: '600', paddingLeft: '5px' }}
                >
                  Filter
                </span>
              </MUIGrid>
              <LastPageIcon />
            </MenuItem>
          </div>
        )}
      </Menu>
    ) : (
      <></>
    )
  }

  const setPageCount = () => {
    return Math.ceil((pagination?.totalCount ?? 1) / Number(pagination.pageLimit))
  }

  const getTableRows = (row: any, rowInd: number, isChild = false) => {
    const rowURL: string = row?.rowURL ?? ''
    return (
      <Fragment key={`main_${rowInd}`}>
        <TableRow
          data-testid='row-test'
          key={`${isChild ? 'child_' : ''}${rowInd}`}
          className={`customRowClickable ${
            rowsClickable ? css.tableRowClickable : row.isRowClickable ? css.tableRowClickable : ''
          }`}
        >
          {headers?.map((header: any, headerIndx) => {
            return (
              <TableCell
                data-testid={`row-test-${headerIndx}`}
                className={`custom-td-child ${
                  sticky && headerIndx === getStickyIndex(sticky, headers)
                    ? css[`list_sticky_header_${sticky}`]
                    : ''
                }`}
                style={sticky ? { minWidth: stickyWidth } : {}}
                key={`${isChild ? 'child_' : ''}${String(rowInd)}` + '_' + String(headerIndx)}
                onClick={(e: any) => {
                  e.preventDefault()
                  if (archived) {
                    onClickRow &&
                      rowInd === 0 &&
                      onClickRow({ row, rowInd, header, headerIndx, isChild })
                  } else {
                    onClickRow && onClickRow({ row, rowInd, header, headerIndx, isChild })
                  }
                }}
              >
                {row.rowURL ? (
                  header?.blitzId ? (
                    <div
                      className={`paragraph-regular-6 interFont${
                        header?.class ? ` ${header.class}` : ''
                      }`}
                    >
                      {renderTableValue(row, header)}
                    </div>
                  ) : (
                    <Link to={rowURL} style={{ textDecoration: 'none' }} className='table-link'>
                      <div
                        className={`paragraph-regular-6 interFont${
                          header?.class ? ` ${header.class}` : ''
                        }`}
                      >
                        {renderTableValue(row, header)}
                      </div>
                    </Link>
                  )
                ) : (
                  <div
                    className={`paragraph-regular-6 interFont${
                      header?.class ? ` ${header.class}` : ''
                    }`}
                  >
                    {renderTableValue(row, header)}
                  </div>
                )}
              </TableCell>
            )
          })}
        </TableRow>
        {!isChild &&
          childRows?.index === rowInd &&
          Array.isArray(childRows?.records) &&
          childRows?.records?.length &&
          getChildRows(childRows.records, rowInd)}
      </Fragment>
    )
  }

  const getChildRows = (childRows: any, rowInd: number) => {
    return (
      <>
        <TableRow
          className='innerOpenedRow'
          key={`child_c_${rowInd}`}
          sx={{ maxWidth: '100% !important' }}
          data-testid='child-row-test'
        >
          <TableCell
            className='p-0 w-100'
            colSpan={headers.length}
            sx={{ maxWidth: '100% !important' }}
          >
            <Box
              sx={{ padding: '20px 0', maxWidth: '100% !important', backgroundColor: '#f5f4f4' }}
            >
              <Table size='small' aria-label='child-tabel' sx={{ backgroundColor: '#FFF' }}>
                <TableBody>
                  {childRows?.map((row: any, rowInd: number) => getTableRows(row, rowInd, true))}
                </TableBody>
              </Table>
            </Box>
          </TableCell>
        </TableRow>
      </>
    )
  }

  return (
    <Grid
      sx={{
        width: '100%',
        backgroundColor: '#f5f4f4',
      }}
    >
      <TableContainer
        className={`custom-commonTable shadow-table ${css.tableContainer} ${className} ${
          transparent ? css.transparent : ''
        }`}
        style={{
          boxShadow: noBoxShadow ? 'none' : '0px 20px 24px -4px rgb(56 63 67 / 8%)',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers?.map((header: CommonTableHeaderModel, headerIndx) => (
                <TableCell
                  key={`header_${String(headerIndx)}`}
                  className={`${
                    sticky && headerIndx === getStickyIndex(sticky, headers)
                      ? css[`list_sticky_header_${sticky}`]
                      : ''
                  }`}
                  style={sticky ? { minWidth: stickyWidth } : {}}
                >
                  <div className={`${css.tableHeader}${header.class ? ` ${header.class}` : ''}`}>
                    <div>{header.label}</div>
                    {header?.icon ? header.icon : ''}
                    {(header.sort || header.filter) && (
                      <>
                        <MUITooltip title='Sort By' disableInteractive={true}>
                          <IconButton
                            data-testid='sort-filter'
                            className='m-4 d-flex align-items-center cursor-pointer'
                            size='small'
                            onClick={(ev) => handleSortFilterOpen(ev, header)}
                          >
                            <FilterSortIcon
                              header={header}
                              sortFilterKey={sortFilterKey}
                              selectedFilters={selectedFilters}
                            />
                          </IconButton>
                        </MUITooltip>
                        {sortFilterUi(header)}
                        {
                          <FilterUi
                            {...{
                              header,
                              filterOpen,
                              anchorEl2,
                              filterValue,
                              setFilterValue,
                              filterConOptions,
                              handleFilterClose,
                            }}
                          />
                        }
                      </>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any, rowInd: number) => getTableRows(row, rowInd))}
          </TableBody>
        </Table>
        {!rows.length && !loader && (
          <Typography className='d-flex justify-content-center' style={{ padding: '50px' }}>
            {noRecordsMessage}
          </Typography>
        )}
      </TableContainer>
      {pagination.show && (
        <MUIGrid
          container
          className='d-flex align-items-center justify-content-between mt-2 c-pagination'
        >
          {((!selectedFilters?.addArchivedData &&
            showArchivalHistory &&
            setPageCount() === pagination?.pageNumber) ||
            (!rows.length && showArchivalHistory)) && (
            <MUIGrid item xs={12} className='mtn-14'>
              <button
                className='fs12 btn-fetching-data col1 ml-auto mb-1'
                onClick={() => onChangeArchivalState(true)}
              >
                <span className='fs12 fetch-refresh-icon'>↻</span>
                {'   '}
                <span className='text-underline'>Fetch older data</span>
              </button>
            </MUIGrid>
          )}

          <MUIGrid item direction='row' className='d-flex align-items-center col1 c-dropdown'>
            <SimpleDropdown
              selectId='pageId'
              options={pagination.limitOptions as any}
              handleChange={(ev: Record<string, any>) =>
                pagination.onPageCountChange(ev.target.value)
              }
              value={pagination.pageLimit}
            />
            <Typography paddingX={3}>
              Showing{' '}
              {pagination.pageLimit > pagination.totalCount ? pagination.totalCount : rows.length}{' '}
              of {pagination.totalCount} records
            </Typography>
          </MUIGrid>
          <MUIGrid item>
            {pagination.totalCount > 0 && (
              <MUIPagination
                data-testid='common-pagination'
                onChange={(_ev, pageNumber) => pagination.onPageChange(pageNumber)}
                count={setPageCount()}
                page={pagination.pageNumber}
              />
            )}
          </MUIGrid>
        </MUIGrid>
      )}
    </Grid>
  )
}

export default MUICommonTable
