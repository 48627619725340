import MUITooltip from '../Shared/MuiTooltipComponent'

const RedCrossIcon = ({ title = '' }: any) => {
  return (
    <MUITooltip title={title} disableInteractive={true}>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Component 12' clipPath='url(#clip0_4455_25617)'>
          <path
            id='Vector'
            d='M18.8 5.71022C18.41 5.32022 17.78 5.32022 17.39 5.71022L12.5 10.5902L7.60997 5.70021C7.21997 5.31021 6.58997 5.31021 6.19997 5.70021C5.80997 6.09021 5.80997 6.72022 6.19997 7.11022L11.09 12.0002L6.19997 16.8902C5.80997 17.2802 5.80997 17.9102 6.19997 18.3002C6.58997 18.6902 7.21997 18.6902 7.60997 18.3002L12.5 13.4102L17.39 18.3002C17.78 18.6902 18.41 18.6902 18.8 18.3002C19.19 17.9102 19.19 17.2802 18.8 16.8902L13.91 12.0002L18.8 7.11022C19.18 6.73022 19.18 6.09022 18.8 5.71022Z'
            fill='#D4351C'
          />
        </g>
        <defs>
          <clipPath id='clip0_4455_25617'>
            <rect width='24' height='24' fill='white' transform='translate(0.5)' />
          </clipPath>
        </defs>
      </svg>
    </MUITooltip>
  )
}

export default RedCrossIcon
