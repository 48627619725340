import CONSTANTS from '../../Constants'
import { isJobCancelledBeforeStart } from '../../Helpers/BulkUpload'
import { downloadBulkUpdateDataFunction } from '../../Helpers/Util'
import { showAlert } from '../../Store/Slice/alert.slice'
import { setcancelBulkUpdate } from '../../Store/Slice/bulkUpdate.slice'
import { BulkUploadCancelIcon, DownloadIcon } from '../Icons/PlayListPlay'
import MUITooltip from '../Shared/MuiTooltipComponent'
import Style from './BulkupdateStyle.module.scss'

const getJobStatus = (status: string) => {
  return (
    <div className={`${Style['bulk-update-status']} ${Style[status]}`}>{bulkStatus(status)}</div>
  )
}
export default getJobStatus
export const bulkStatus = (status: string) => {
  if (status === 'IN_PROGRESS') {
    return 'In Progress'
  } else if (status === 'COMPLETED') {
    return 'Completed'
  } else if (status === 'SCHEDULED') {
    return 'Scheduled'
  } else if (status === 'IN_QUEUE') {
    return 'In Queue'
  } else {
    return status
  }
}

export const bulkUpdateListingAction = (data: any, dispatch: any) => {
  const cancelCondition: any[] = ['IN_PROGRESS', 'IN_QUEUE', 'SCHEDULED']
  return (
    <span className='d-flex allign-items-center mbn-7'>
      <MUITooltip
        title={data.status === 'COMPLETED' ? 'Download Report' : ''}
        disableInteractive={true}
      >
        <span
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (isJobCancelledBeforeStart(data)) {
              dispatch(showAlert([true, CONSTANTS.BULK_UPDATE_CANCELLED_JOB, 'error']))
              return
            }
            data.status === 'COMPLETED' && downloadBulkUpdateDataFunction(data, dispatch)
          }}
          data-testid='download-id'
          style={{
            opacity: data.status !== 'COMPLETED' ? 0.5 : 1,
          }}
        >
          <DownloadIcon />
        </span>
      </MUITooltip>
      &nbsp;&nbsp;&nbsp;
      <MUITooltip
        title={
          data.status === 'IN_PROGRESS' || data.status === 'IN_QUEUE' || data.status === 'SCHEDULED'
            ? 'Cancel Job'
            : ''
        }
        disableInteractive={true}
      >
        <span
          data-testid='cancel-id'
          onClick={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            if (
              data.status === 'IN_PROGRESS' ||
              data.status === 'IN_QUEUE' ||
              data.status === 'SCHEDULED'
            ) {
              dispatch(
                setcancelBulkUpdate({
                  show: true,
                  data: data || {},
                }) as any,
              )
            }
          }}
          style={{
            opacity: cancelCondition?.includes(data.status) ? 1 : 0.5,
          }}
        >
          <BulkUploadCancelIcon />
        </span>
      </MUITooltip>
    </span>
  )
}
