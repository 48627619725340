import { Tooltip } from '@mui/material'

interface MUITooltipProps {
  title?: any
  children?: any
  placement?: any
  props?: any
  disableInteractive?: boolean
  arrow?: boolean
  componentsProps?: any
  sx?: any
}
const MUITooltip = ({
  title,
  children,
  placement = 'bottom',
  disableInteractive = true,
  arrow = true,
  componentsProps = {
    tooltip: {
      sx: {
        padding: '4px 8px 4px 8px',
        fontSize: '12px',
        fontWeight: '500',
        color: '#402080',
        bgcolor: '#ECE9F2',
        '& .MuiTooltip-arrow': {
          color: '#ECE9F2',
        },
      },
    },
  },
  sx = {},
  ...props
}: MUITooltipProps) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={arrow}
      componentsProps={componentsProps}
      disableInteractive={disableInteractive}
      sx={sx}
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export default MUITooltip
