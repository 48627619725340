import MUITooltip from '../Shared/MuiTooltipComponent'

function CircleMinus({ title = '' }: any) {
  return (
    <MUITooltip title={title} disableInteractive={true}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Component 25' clipPath='url(#clip0_2586_13217)'>
          <path
            id='Vector'
            d='M7 12C7 12.55 7.45 13 8 13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H8C7.45 11 7 11.45 7 12ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z'
            fill='#858789'
          />
        </g>
        <defs>
          <clipPath id='clip0_2586_13217'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </MUITooltip>
  )
}

export default CircleMinus
