import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {}

const assetValidationErrorSlice = createSlice({
  name: 'assetValidationError',
  initialState,
  reducers: {
    setValidtionErrors: (
      state,
      action: PayloadAction<{ versionId: string; category: string; errors: string[] }>,
    ) => {
      const { versionId, category, errors } = action.payload
      // If category is provided, update only that category
      if (!state[versionId]) {
        state[versionId] = {}
      }
      state[versionId][category] = errors
    },
    removeValidationErrors: (
      state,
      action: PayloadAction<{ versionId: string; category?: string }>,
    ) => {
      const { versionId, category } = action.payload
      if (state[versionId]) {
        if (category) {
          if (state[versionId]?.['CHECKLIST']?.length) {
            // Remove errors from a specific category
            state[versionId]['CHECKLIST'] = state[versionId]['CHECKLIST'].filter(
              (item: any) => item.metaCategory !== category,
            )
          }
          // Remove errors from a specific category
          delete state[versionId][category]
        } else {
          // Remove all errors for that versionId
          delete state[versionId]
        }
      }
    },
  },
})

// Export actions and reducer
export const { setValidtionErrors, removeValidationErrors } = assetValidationErrorSlice.actions
export default assetValidationErrorSlice
